import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Button } from '@material-ui/core'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import { OutboundLink } from 'gatsby-plugin-gtag'
import { useStaticQuery, graphql } from "gatsby"

import Logo from '../../images/logo.png'
import LogoTecnico from '../../images/logo-tecnico.png'
import LogoAll from '../../images/jardins_all_logo.png'
import Menu from '../HoverableMenu'
import Drawer from '../Drawer'
import { Div, LogoWrapper, ActionButton } from './styles'

export default function Header(props) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  function toggleDrawer() {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "ESTRUTURA DE POLOS DA FACULDADE JARDINS" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  return (
    <Div component="header">
      <LogoWrapper>
        <Link to="/">
          <img src={props.polo === true ? LogoTecnico : Logo} alt="logo" />
        </Link>
      </LogoWrapper>
      <div className="menu-wrapper">
        <Menu
          text="Graduação"
          items={[
            { name: 'Presencial na Faculdade Jardins - Aracaju', link: '/presencial/graduacao' },
            // { name: 'Semipresencial', link: '/semipresencial/graduacao' },
            { name: 'EaD', link: '/ead/graduacao' },
            { name: 'Presencial na Faculdade Jardins ALL - Lagarto', link: '/presencial/all-lagarto' },
            // { name: 'Segunda Graduação', link: '/segunda-graduacao' },
          ]}
        />
        <Menu
          text="Pós-Graduação"
          items={[
            { name: 'Presencial', link: '/presencial/pos-graduacao' },
            // { name: 'Semipresencial', link: '/semipresencial/pos-graduacao' },
            { name: 'EaD', link: '/ead/pos-graduacao' },
          ]}
        />
        <Link to="/extensao">Extensão</Link>
        <Menu
          text="Curso Técnico"
          items={[
            { name: 'Presencial', link: '/curso-tecnico/presencial' },
            { name: 'EaD', link: '/curso-tecnico/ead' },
          ]}
        />
        <Menu
          text="Institucional"
          items={[
            { name: 'Matrícula Presencial', link: 'https://faculdadejardins-com-br.rds.land/ld-camp-de-matriculas-23-24', type: 'external' },
            { name: 'Matrícula EaD', link: 'https://faculdadejardins-com-br.rds.land/ld-camp-de-matriculas-23-24', type: 'external' },
            { name: 'Sistema Acadêmico', link: 'https://app12.activesoft.com.br/sistema/entrar.asp?p=IES_FACJARDINS', type: 'external' },
            { name: 'Perguntas e Respostas', link: '/faq' },
            { name: 'Documentos Institucionais', link: '/documentos' },
            { name: 'Requisições', link: '/requisicoes' },
            { name: 'Repositório', link: 'http://faculdadejardins-com-br.rds.land/repositorio-faculdade-jardins', type: 'external' },
            { name: 'NAE - Núcleo de apoio ao estudante', link: '/nae' },
            { name: 'Diplomas registrados', link: '/diplomas-registrados' },
            { name: 'Comissão própria de avaliação (CPA)', link: '/cpa' },
            { name: 'Nossa História', link: '/nossa-historia' },
          ]}
        />
        <Menu
          text="Polos"
          items={[
            { name: 'Nossos Polos', link: '/polos' },
            { name: 'Seja nosso polo', link: 'https://faculdadejardins-com-br.rds.land/polos', type: 'external' },
            { name: 'Estrutura dos Polos', link: 'https://faculdadejardins-com-br.rds.land/estrutura-do-polo', type: 'external' },
          ]}
        />
        <Link to="/vestibular">Vestibular</Link>
        <Link to="/pesquisa">Pesquisa e Inovação</Link>
      </div>
      {/* <ActionButton href="https://conteudo.faculdadejardins.com.br/matricula" target="_blank" className="action-button">
        Inscreva-se
      </ActionButton> */}
      <Button className="menu-button" onClick={toggleDrawer}>
        <MenuRoundedIcon style={{ color: '#fff' }} />
      </Button>
      <Drawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <LogoWrapper>
        <Link to="/">
          <img src={props.polo === true ? LogoTecnico : LogoAll} className="logo-all-header" alt="logo" />
        </Link>
      </LogoWrapper>
    </Div>
  )
}
